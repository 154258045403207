import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiClients } from "api/clients";
import { LegacyDealApi } from "api/legacyApi";
import { createAppSelector } from "./hooks";
import type { RootState } from "./configureStore";

const getRequestHash = async (request?: object) => {
  try {
    const hash = await window.crypto.subtle.digest("SHA-256", new TextEncoder().encode(JSON.stringify(request || {}).toLowerCase()));

    return Array.from(new Uint8Array(hash))
      .map(x => x.toString(16).toUpperCase())
      .join("");
  } catch (ex) {
    console.error(ex);
  }
};

interface ProfileByIdStore extends Record<string, LegacyDealApi.ProfileGetResponseModel> {}

export const loadProfileById = (id: string | string[], force?: boolean) => loadProfile({ id, force: force === true });
export const loadProfile = createAsyncThunk<
  { Body?: LegacyDealApi.ProfileGetResponseModel | undefined }[],
  { id: string | string[]; force?: boolean },
  { state: RootState }
>(
  "profiles/loadProfileById",
  async (x, api) => {
    return await Promise.all(
      (Array.isArray(x.id) ? x.id : [x.id]).filter(y => !!y).map(y => ApiClients.legacy.profilesApi.getProfile(y, api.signal))
    );
  },
  {
    condition: (arg, api) => {
      const { force, id } = arg;
      if (force) return true;

      const loadingIds = Object.keys(api.getState().ProfileData.profileById).map(x => x.toLowerCase());
      const profileIds = Array.isArray(id) ? id : [id];

      return profileIds.some(x => !!x && !loadingIds.includes(x?.toLowerCase() || ""));
    }
  }
);

export const loadProfiles = createAsyncThunk<
  LegacyDealApi.ProfileGetSummaryResponseModel | undefined,
  true | undefined,
  { state: RootState }
>(
  "profiles/loadProfiles",
  async (_, api) => {
    return await ApiClients.legacy.profilesApi.getSummary(api.signal).then(x => x.Body);
  },
  {
    condition: (arg, api) => {
      if (arg === true) return true;

      const currentValue = api.getState().ProfileData.profiles;
      return currentValue.loading === false && currentValue.data == null;
    }
  }
);

export const loadPeriodHours = createAsyncThunk<
  { requestId: string; data: LegacyDealApi.ContractPeriodModel | undefined },
  LegacyDealApi.ProfileHoursRequestModel,
  { state: RootState }
>(
  "profiles/loadPeriodHours",
  async (x, api) => {
    const id = (await getRequestHash(x)) || "";
    if (!!x) {
      return {
        requestId: id,
        data: (await ApiClients.legacy.configApi.getHoursInPeriod(x, api.signal))?.Body
      };
    }
    return { requestId: id, data: undefined };
  },
  {
    condition: async (arg, api) => {
      const currentState = api.getState().ProfileData.contractHours;
      const requestId = await getRequestHash(arg);

      return currentState.loading === false && currentState.id !== requestId;
    }
  }
);

export const loadProfileLookupData = createAsyncThunk<LegacyDealApi.DayTypeGetResponseModel | undefined, void, { state: RootState }>(
  "profiles/loadProfileLookupData",
  async (x, api) => {
    return await ApiClients.legacy.dayTypeApi.getDayTypes(api.signal).then(x => x.Body);
  },
  {
    condition: async (_, api) => {
      const currentState = api.getState().ProfileData.lookupData;

      return !currentState.DayTypes.loading && (currentState.DayTypes.data?.length || 0) < 1;
    }
  }
);

export const expandProfile = createAsyncThunk<
  { data?: LegacyDealApi.ProfileExpandedGetResponseModel; requestId: string },
  {
    profileId: string;
    startDate?: string | undefined;
    endDate?: string | undefined;
    market?: string | undefined;
    state?: string | undefined;
    frequency?: number | undefined;
    signal?: AbortSignal;
  },
  { state: RootState }
>(
  "profiles/expandProfile",
  async x => {
    const expandedResult = (
      await ApiClients.legacy.profilesApi.getProfileExpanded(
        x.profileId,
        x.startDate!,
        x.endDate!,
        x.market,
        x.state,
        x.frequency,
        x.signal
      )
    )?.Body;

    return { data: expandedResult, requestId: (await getRequestHash(x)) || "" };
  },
  {
    condition: arg => !!arg && arg.startDate != null && arg.endDate != null && arg.startDate != null && arg.endDate != null
  }
);

interface DataEntry<T> {
  loading: boolean;
  id: string;
  data?: T;
  error?: string;
}

const initialState: {
  profiles: DataEntry<LegacyDealApi.ProfileGetSummaryResponseModel>;
  profileById: Record<string, DataEntry<LegacyDealApi.ProfileGetResponseModel>>;
  contractHours: DataEntry<LegacyDealApi.ContractPeriodModel>;
  expandedProfile: DataEntry<LegacyDealApi.ProfileExpandedGetResponseModel>;
  lookupData: {
    DayTypes: DataEntry<LegacyDealApi.DayTypeGetDayTypeResponseModel[]>;
  };
} = {
  profiles: { id: "", loading: false },
  profileById: {},
  contractHours: {
    id: "",
    loading: false
  },
  expandedProfile: {
    id: "",
    loading: false
  },
  lookupData: {
    DayTypes: { loading: false, id: "" }
  }
};

const profileDataSlice = createSlice({
  initialState,
  name: "profileStore",

  extraReducers: builder => {
    builder
      .addCase(loadProfiles.pending, state => {
        state.profiles.loading = true;
      })
      .addCase(loadProfiles.fulfilled, (state, action) => {
        Object.assign(state.profiles, {
          id: "",
          loading: false,
          data: action.payload,
          error: undefined
        });
      })
      .addCase(loadProfiles.rejected, (state, action) => {
        console.error(action.error);
        Object.assign(state.profiles, {
          id: "",
          loading: false,
          error: action.error.message
        });
      })

      .addCase(loadProfile.pending, (state, id) => {
        const idValues = Array.isArray(id.meta.arg.id) ? id.meta.arg.id : [id.meta.arg.id];
        state.profileById = idValues
          .map(n => ({
            loading: true,
            id: n?.toLowerCase() || "",
            data: undefined
          }))
          .reduce<Record<string, DataEntry<LegacyDealApi.ProfileGetResponseModel>>>((list, p) => ({ ...list, [p.id]: p }), {});
      })
      .addCase(loadProfile.fulfilled, (state, result) => {
        state.profileById.data = {
          ...state.profileById.data,
          ...result.payload
            .filter(x => !!x?.Body)
            .reduce<ProfileByIdStore>((list, n) => ({ ...list, [n.Body!.ProfileId?.toUpperCase() || ""]: n.Body! }), {})
        };
      })
      .addCase(loadProfile.rejected, (state, action) => {
        console.error(action.error);

        const idValues = Array.isArray(action.meta.arg.id) ? action.meta.arg.id : [action.meta.arg.id];
        for (const id of idValues) {
          state.profileById[id].loading = false;
          state.profileById[id].error = action.error?.message;
        }
      })
      .addCase(loadPeriodHours.pending, (state, request) => {
        state.contractHours.loading = true;
      })
      .addCase(loadPeriodHours.fulfilled, (state, result) => {
        state.contractHours.data = result.payload.data;
        state.contractHours.id = result.payload.requestId;
        state.contractHours.loading = false;
      })
      .addCase(loadPeriodHours.rejected, (state, result) => {
        console.error(result.error);

        state.contractHours.loading = false;
        state.contractHours.error = result.error?.message;
      })
      .addCase(expandProfile.pending, (state, request) => {
        state.contractHours.loading = true;
      })
      .addCase(expandProfile.fulfilled, (state, result) => {
        state.expandedProfile.data = result.payload.data;
        state.expandedProfile.id = result.payload.requestId;
        state.expandedProfile.loading = false;
      })
      .addCase(expandProfile.rejected, (state, result) => {
        state.expandedProfile.loading = false;
        state.expandedProfile.error = result.error?.message;
      });
  },
  reducers: {}
});

export const selectPeriodHours = createAppSelector(
  (x: RootState) => x.ProfileData.contractHours.data,
  s => s
);

export const selectProfile = createAppSelector(
  [(state: RootState) => state.ProfileData.profileById, (_: RootState, id: string) => id],
  (profiles, id) => {
    return profiles[id];
  }
);

export const selectProfiles = createAppSelector(
  [(state: RootState) => state.ProfileData.profiles],
  profiles => profiles.data?.Profiles || []
);
export const selectProfileNormalizations = createAppSelector(
  [(state: RootState) => state.ProfileData.profiles],
  profiles => profiles.data?.Normalisations || []
);

export const selectExpandedProfile = createAppSelector(
  (x: RootState) => x.ProfileData.expandedProfile.data,
  data => data
);

export default profileDataSlice.reducer;
