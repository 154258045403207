import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { History } from "history";
import { tickerSelectorReducer } from "app/features/POWER/ASX/TickerSelector";
import { ConfigReducer } from "sagas/reducers/config";
import { ProfilesReducer } from "sagas/reducers/profiles";
import { feeSelectorReducer } from "app/features/SHARED/Fees/reducers";
import { dealDetailsSelectorReducer } from "app/features/SHARED/InternalDetails/reducers";
import { firmnessSelectorReducer } from "app/features/SHARED/Firmness/reducers";
import { counterpartySelectorReducer } from "app/features/SHARED/Counterparty/reducers";
import { gasDealSelectorReducer } from "app/features/GAS/GSH/reducer";
import { powerDealSelectorReducer } from "app/features/POWER/reducer";
import { DealsReducer } from "sagas/reducers/deals";
import { DayTypesReducer } from "sagas/reducers/daytypes";
import { IndexesSagaReducer } from "sagas-v2/reducers/indexes";
import siteConfigurationSlice from "./siteConfigurationSlice";
import feeConfigurationSlice from "./feesConfigurationSlice";
import marketDealsSlice from "./marketDealsSlice";
import internalConfigSlice from "./internalConfigSlice";
import profileDataSlice from "./profileDataSlice";
import dealDataSlice from "./dealDataSlice";

// root reducer
const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    tickerSelector: tickerSelectorReducer,
    powerDealSelector: powerDealSelectorReducer,
    gasDealSelector: gasDealSelectorReducer,
    firmnessSelector: firmnessSelectorReducer,
    counterpartySelector: counterpartySelectorReducer,
    feeSelector: feeSelectorReducer,
    dealDetailsSelector: dealDetailsSelectorReducer,
    ConfigSagaState: ConfigReducer,
    ProfilesSagaState: ProfilesReducer,
    DayTypesSagaState: DayTypesReducer,
    DealsSagaState: DealsReducer,
    SiteConfiguration: siteConfigurationSlice,
    V2IndexesSagaState: IndexesSagaReducer,
    FeeConfiguration: feeConfigurationSlice,
    MarketDeals: marketDealsSlice,
    UserData: internalConfigSlice,
    ProfileData: profileDataSlice,
    DealData: dealDataSlice
  });

export default rootReducer;
